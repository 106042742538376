/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React, { StrictMode } from "react";
import { MDXProvider } from "@mdx-js/react";
import { Typography } from "antd";
import styled from "@emotion/styled";
import { colors } from "./src/theme/variables";

const StyledTitle = styled(Typography.Title)`
  margin-top: 1rem;
  margin-bottom: 2rem;
  &.ant-typography {
    color: ${colors.blue.primary};
  }
`;

export const wrapRootElement = ({ element }) => {
  return (
    <StrictMode>
      <MDXProvider
        components={{
          // eslint-disable-next-line react/display-name
          h1: (props) => (
            <StyledTitle level={3} {...props}>
              {props.children}
            </StyledTitle>
          ),
        }}
      >
        {element}
      </MDXProvider>
    </StrictMode>
  );
};
